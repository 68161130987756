@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local(Roboto Thin ), local(Roboto-Thin), url("roboto-latin-100.401f3749.woff2") format("woff2"), url("roboto-latin-100.28789877.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src: local(Roboto Thin italic), local(Roboto-Thinitalic), url("roboto-latin-100italic.a1931ae4.woff2") format("woff2"), url("roboto-latin-100italic.f980b363.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local(Roboto Light ), local(Roboto-Light), url("roboto-latin-300.81a5bc04.woff2") format("woff2"), url("roboto-latin-300.9527f5b1.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: local(Roboto Light italic), local(Roboto-Lightitalic), url("roboto-latin-300italic.4ad36a98.woff2") format("woff2"), url("roboto-latin-300italic.470fc714.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(Roboto Regular ), local(Roboto-Regular), url("roboto-latin-400.962f1284.woff2") format("woff2"), url("roboto-latin-400.541b858a.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local(Roboto Regular italic), local(Roboto-Regularitalic), url("roboto-latin-400italic.726359d1.woff2") format("woff2"), url("roboto-latin-400italic.876e597b.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local(Roboto Medium ), local(Roboto-Medium), url("roboto-latin-500.b3c683d1.woff2") format("woff2"), url("roboto-latin-500.5f8bfa54.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: local(Roboto Medium italic), local(Roboto-Mediumitalic), url("roboto-latin-500italic.21775980.woff2") format("woff2"), url("roboto-latin-500italic.605cdec2.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(Roboto Bold ), local(Roboto-Bold), url("roboto-latin-700.41e719aa.woff2") format("woff2"), url("roboto-latin-700.f439c182.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local(Roboto Bold italic), local(Roboto-Bolditalic), url("roboto-latin-700italic.00f6a7b9.woff2") format("woff2"), url("roboto-latin-700italic.55756cd7.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local(Roboto Black ), local(Roboto-Black), url("roboto-latin-900.0f04a344.woff2") format("woff2"), url("roboto-latin-900.c4cd6965.woff") format("woff");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: local(Roboto Black italic), local(Roboto-Blackitalic), url("roboto-latin-900italic.39f8a7c1.woff2") format("woff2"), url("roboto-latin-900italic.fa7a4a2e.woff") format("woff");
}

.jsoneditor input, .jsoneditor input:not([type]), .jsoneditor input[type="text"], .jsoneditor input[type="search"], .jsoneditor-modal input, .jsoneditor-modal input:not([type]), .jsoneditor-modal input[type="text"], .jsoneditor-modal input[type="search"] {
  height: auto;
  border: inherit;
  box-shadow: none;
  font-size: inherit;
  box-sizing: inherit;
  padding: inherit;
  font-family: inherit;
  line-height: inherit;
  transition: none;
}

.jsoneditor input:focus, .jsoneditor input:not([type]):focus, .jsoneditor input[type="text"]:focus, .jsoneditor input[type="search"]:focus, .jsoneditor-modal input:focus, .jsoneditor-modal input:not([type]):focus, .jsoneditor-modal input[type="text"]:focus, .jsoneditor-modal input[type="search"]:focus {
  border: inherit;
  box-shadow: inherit;
}

.jsoneditor textarea, .jsoneditor-modal textarea {
  height: inherit;
}

.jsoneditor select, .jsoneditor-modal select {
  display: inherit;
  height: inherit;
}

.jsoneditor label, .jsoneditor-modal label {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

.jsoneditor table, .jsoneditor-modal table {
  border-collapse: collapse;
  width: auto;
}

.jsoneditor td, .jsoneditor th, .jsoneditor-modal td, .jsoneditor-modal th {
  text-align: left;
  vertical-align: inherit;
  border-radius: inherit;
  padding: 0;
  display: table-cell;
}

.jsoneditor .autocomplete.dropdown {
  cursor: default;
  text-align: left;
  background: #fff;
  border: 1px solid #d3d3d3;
  outline: 0;
  margin: 0;
  padding: 5px;
  font-family: consolas, menlo, monaco, Ubuntu Mono, source-code-pro, monospace;
  font-size: 14px;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 2px 2px 12px rgba(128, 128, 128, .3);
}

.jsoneditor .autocomplete.dropdown .item {
  color: #1a1a1a;
}

.jsoneditor .autocomplete.dropdown .item.hover {
  background-color: #ebebeb;
}

.jsoneditor .autocomplete.hint {
  color: #a1a1a1;
  top: 4px;
  left: 4px;
}

.jsoneditor-contextmenu-root {
  width: 0;
  height: 0;
  position: relative;
}

.jsoneditor-contextmenu {
  box-sizing: content-box;
  z-index: 2;
  position: absolute;
}

.jsoneditor-contextmenu .jsoneditor-menu {
  width: 128px;
  height: auto;
  background: #fff;
  border: 1px solid #d3d3d3;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  top: 0;
  left: 0;
  box-shadow: 2px 2px 12px rgba(128, 128, 128, .3);
}

.jsoneditor-contextmenu .jsoneditor-menu button {
  width: 128px;
  height: auto;
  cursor: pointer;
  color: #4d4d4d;
  box-sizing: border-box;
  text-align: left;
  background: none;
  border: none;
  margin: 0;
  padding: 0 8px 0 0;
  font-family: arial, sans-serif;
  font-size: 14px;
  position: relative;
}

.jsoneditor-contextmenu .jsoneditor-menu button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.jsoneditor-contextmenu .jsoneditor-menu button.jsoneditor-default {
  width: 96px;
}

.jsoneditor-contextmenu .jsoneditor-menu button.jsoneditor-expand {
  float: right;
  width: 32px;
  height: 24px;
  border-left: 1px solid #e5e5e5;
}

.jsoneditor-contextmenu .jsoneditor-menu li {
  overflow: hidden;
}

.jsoneditor-contextmenu .jsoneditor-menu li ul {
  -o-transition: all .3s ease-out;
  border: none;
  padding: 0 10px;
  transition: all .3s ease-out;
  display: none;
  position: relative;
  top: 0;
  left: -10px;
  box-shadow: inset 0 0 10px rgba(128, 128, 128, .5);
}

.jsoneditor-contextmenu .jsoneditor-menu li ul .jsoneditor-icon {
  margin-left: 24px;
}

.jsoneditor-contextmenu .jsoneditor-menu li ul li button {
  padding-left: 24px;
  -webkit-animation: 1s ease-in-out all;
  animation: 1s ease-in-out all;
}

.jsoneditor-contextmenu .jsoneditor-menu li button .jsoneditor-expand {
  width: 24px;
  height: 24px;
  background-image: url("jsoneditor-icons.0b983fb8.svg");
  background-position: 0 -72px;
  margin: 0 4px 0 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.jsoneditor-contextmenu .jsoneditor-icon {
  width: 24px;
  height: 24px;
  background-image: url("jsoneditor-icons.0b983fb8.svg");
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.jsoneditor-contextmenu .jsoneditor-text {
  word-wrap: break-word;
  padding: 4px 0 4px 24px;
}

.jsoneditor-contextmenu .jsoneditor-text.jsoneditor-right-margin {
  padding-right: 24px;
}

.jsoneditor-contextmenu .jsoneditor-separator {
  height: 0;
  border-top: 1px solid #e5e5e5;
  margin-top: 5px;
  padding-top: 5px;
}

.jsoneditor-contextmenu button.jsoneditor-remove .jsoneditor-icon {
  background-position: -24px 0;
}

.jsoneditor-contextmenu button.jsoneditor-append .jsoneditor-icon, .jsoneditor-contextmenu button.jsoneditor-insert .jsoneditor-icon {
  background-position: 0 0;
}

.jsoneditor-contextmenu button.jsoneditor-duplicate .jsoneditor-icon {
  background-position: -48px 0;
}

.jsoneditor-contextmenu button.jsoneditor-sort-asc .jsoneditor-icon {
  background-position: -168px 0;
}

.jsoneditor-contextmenu button.jsoneditor-sort-desc .jsoneditor-icon {
  background-position: -192px 0;
}

.jsoneditor-contextmenu button.jsoneditor-transform .jsoneditor-icon {
  background-position: -216px 0;
}

.jsoneditor-contextmenu button.jsoneditor-extract .jsoneditor-icon {
  background-position: 0 -24px;
}

.jsoneditor-contextmenu button.jsoneditor-type-string .jsoneditor-icon {
  background-position: -144px 0;
}

.jsoneditor-contextmenu button.jsoneditor-type-auto .jsoneditor-icon {
  background-position: -120px 0;
}

.jsoneditor-contextmenu button.jsoneditor-type-object .jsoneditor-icon {
  background-position: -72px 0;
}

.jsoneditor-contextmenu button.jsoneditor-type-array .jsoneditor-icon {
  background-position: -96px 0;
}

.jsoneditor-contextmenu button.jsoneditor-type-modes .jsoneditor-icon {
  width: 6px;
  background-image: none;
}

.jsoneditor-contextmenu ul, .jsoneditor-contextmenu li {
  box-sizing: content-box;
  position: relative;
}

.jsoneditor-contextmenu .jsoneditor-menu button:hover, .jsoneditor-contextmenu .jsoneditor-menu button:focus {
  color: #1a1a1a;
  background-color: #f5f5f5;
  outline: none;
}

.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected, .jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:hover, .jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:focus {
  color: #fff;
  background-color: #ee422e;
}

.jsoneditor-contextmenu .jsoneditor-menu li ul li button:hover, .jsoneditor-contextmenu .jsoneditor-menu li ul li button:focus {
  background-color: #f5f5f5;
}

.jsoneditor-modal {
  max-width: 95%;
  color: #4d4d4d;
  line-height: 1.3em;
  box-shadow: 2px 2px 12px rgba(128, 128, 128, .3);
  border-radius: 2px !important;
  padding: 45px 15px 15px !important;
}

.jsoneditor-modal.jsoneditor-modal-transform {
  width: 600px !important;
}

.jsoneditor-modal .pico-modal-header {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  color: #fff;
  background: #3883fa;
  padding: 0 10px;
  font-family: arial, sans-serif;
  font-size: 11pt;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.jsoneditor-modal table {
  width: 100%;
}

.jsoneditor-modal table td {
  padding: 3px 0;
}

.jsoneditor-modal table td.jsoneditor-modal-input {
  text-align: right;
  white-space: nowrap;
  padding-right: 0;
}

.jsoneditor-modal table td.jsoneditor-modal-actions {
  padding-top: 15px;
}

.jsoneditor-modal table th {
  vertical-align: middle;
}

.jsoneditor-modal p:first-child {
  margin-top: 0;
}

.jsoneditor-modal a {
  color: #3883fa;
}

.jsoneditor-modal .jsoneditor-jmespath-block {
  margin-bottom: 10px;
}

.jsoneditor-modal .pico-close {
  color: #fff;
  background: none !important;
  font-size: 24px !important;
  top: 7px !important;
  right: 7px !important;
}

.jsoneditor-modal input {
  padding: 4px;
}

.jsoneditor-modal input[type="text"] {
  cursor: inherit;
}

.jsoneditor-modal input[disabled] {
  color: gray;
  background: #d3d3d3;
}

.jsoneditor-modal .jsoneditor-select-wrapper {
  display: inline-block;
  position: relative;
}

.jsoneditor-modal .jsoneditor-select-wrapper:after {
  content: "";
  width: 0;
  height: 0;
  pointer-events: none;
  border-top: 6px solid #666;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 14px;
  right: 8px;
}

.jsoneditor-modal select {
  min-width: 180px;
  max-width: 350px;
  -webkit-appearance: none;
  appearance: none;
  text-indent: 0;
  text-overflow: "";
  padding: 3px 24px 3px 10px;
  font-size: 14px;
  line-height: 1.5em;
}

.jsoneditor-modal select::-ms-expand {
  display: none;
}

.jsoneditor-modal .jsoneditor-button-group input {
  border-left-style: none;
  border-radius: 0;
  margin: 0;
  padding: 4px 10px;
}

.jsoneditor-modal .jsoneditor-button-group input.jsoneditor-button-first {
  border-left-style: solid;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.jsoneditor-modal .jsoneditor-button-group input.jsoneditor-button-last {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.jsoneditor-modal .jsoneditor-transform-preview {
  height: 200px;
  background: #f5f5f5;
}

.jsoneditor-modal .jsoneditor-transform-preview.jsoneditor-error {
  color: #ee422e;
}

.jsoneditor-modal .jsoneditor-jmespath-wizard {
  width: 100%;
  border-radius: 3px;
  padding: 0;
  line-height: 1.2em;
}

.jsoneditor-modal .jsoneditor-jmespath-label {
  color: #1e90ff;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: bold;
}

.jsoneditor-modal .jsoneditor-jmespath-wizard-table {
  width: 100%;
  border-collapse: collapse;
}

.jsoneditor-modal .jsoneditor-jmespath-wizard-label {
  margin: 4px 0 2px;
  font-style: italic;
}

.jsoneditor-modal .jsoneditor-inline {
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  display: inline-block;
  position: relative;
}

.jsoneditor-modal .jsoneditor-inline:not(:last-child) {
  padding-right: 2px;
}

.jsoneditor-modal .jsoneditor-jmespath-filter {
  flex-wrap: wrap;
  display: flex;
}

.jsoneditor-modal .jsoneditor-jmespath-filter-field {
  width: 180px;
}

.jsoneditor-modal .jsoneditor-jmespath-filter-relation {
  width: 100px;
}

.jsoneditor-modal .jsoneditor-jmespath-filter-value {
  min-width: 180px;
  flex: 1;
}

.jsoneditor-modal .jsoneditor-jmespath-sort-field {
  width: 170px;
}

.jsoneditor-modal .jsoneditor-jmespath-sort-order {
  width: 150px;
}

.jsoneditor-modal .jsoneditor-jmespath-select-fields {
  width: 100%;
}

.jsoneditor-modal .selectr-selected {
  border-color: #d3d3d3;
  padding: 4px 28px 4px 8px;
}

.jsoneditor-modal .selectr-selected .selectr-tag {
  background-color: #3883fa;
  border-radius: 5px;
}

.jsoneditor-modal table th, .jsoneditor-modal table td {
  text-align: left;
  vertical-align: middle;
  color: #4d4d4d;
  border-spacing: 0;
  border-collapse: collapse;
  font-weight: normal;
}

.jsoneditor-modal select, .jsoneditor-modal textarea, .jsoneditor-modal input, .jsoneditor-modal input[type="text"], .jsoneditor-modal input[type="text"]:focus, .jsoneditor-modal #query {
  color: #4d4d4d;
  background: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  padding: 4px;
}

.jsoneditor-modal textarea, .jsoneditor-modal #query {
  border-radius: unset;
}

.jsoneditor-modal, .jsoneditor-modal table td, .jsoneditor-modal table th, .jsoneditor-modal select, .jsoneditor-modal option, .jsoneditor-modal textarea, .jsoneditor-modal input, .jsoneditor-modal input[type="text"], .jsoneditor-modal #query {
  font-family: arial, sans-serif;
  font-size: 10.5pt;
}

.jsoneditor-modal #query, .jsoneditor-modal .jsoneditor-transform-preview {
  width: 100%;
  box-sizing: border-box;
  font-family: consolas, menlo, monaco, Ubuntu Mono, source-code-pro, monospace;
  font-size: 14px;
}

.jsoneditor-modal input[type="button"], .jsoneditor-modal input[type="submit"] {
  background: #f5f5f5;
  padding: 4px 20px;
}

.jsoneditor-modal select, .jsoneditor-modal input {
  cursor: pointer;
}

.jsoneditor-modal .jsoneditor-button-group.jsoneditor-button-group-value-asc input.jsoneditor-button-asc, .jsoneditor-modal .jsoneditor-button-group.jsoneditor-button-group-value-desc input.jsoneditor-button-desc {
  color: #fff;
  background: #3883fa;
  border-color: #3883fa;
}

.jsoneditor {
  color: #1a1a1a;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: thin solid #3883fa;
  padding: 0;
  line-height: 100%;
  position: relative;
}

div.jsoneditor-field, div.jsoneditor-value, div.jsoneditor-readonly, div.jsoneditor-default {
  min-height: 16px;
  min-width: 32px;
  word-wrap: break-word;
  float: left;
  border: 1px solid rgba(0, 0, 0, 0);
  margin: 1px;
  padding: 2px;
  line-height: 16px;
}

div.jsoneditor-field p, div.jsoneditor-value p {
  margin: 0;
}

div.jsoneditor-value {
  word-break: break-word;
}

div.jsoneditor-value.jsoneditor-empty:after {
  content: "value";
}

div.jsoneditor-value.jsoneditor-string {
  color: #006000;
}

div.jsoneditor-value.jsoneditor-number {
  color: #ee422e;
}

div.jsoneditor-value.jsoneditor-boolean {
  color: #ff8c00;
}

div.jsoneditor-value.jsoneditor-null {
  color: #004ed0;
}

div.jsoneditor-value.jsoneditor-color-value, div.jsoneditor-value.jsoneditor-invalid {
  color: #1a1a1a;
}

div.jsoneditor-readonly {
  min-width: 16px;
  color: gray;
}

div.jsoneditor-empty {
  border-style: dashed;
  border-color: #d3d3d3;
  border-radius: 2px;
}

div.jsoneditor-field.jsoneditor-empty:after {
  content: "field";
}

div.jsoneditor td {
  vertical-align: top;
}

div.jsoneditor td.jsoneditor-separator {
  vertical-align: top;
  color: gray;
  padding: 3px 0;
}

div.jsoneditor td.jsoneditor-tree {
  vertical-align: top;
}

div.jsoneditor.busy pre.jsoneditor-preview {
  color: gray;
  background: #f5f5f5;
}

div.jsoneditor.busy div.jsoneditor-busy {
  display: inherit;
}

div.jsoneditor code.jsoneditor-preview {
  background: none;
}

div.jsoneditor.jsoneditor-mode-preview pre.jsoneditor-preview {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  white-space: pre-wrap;
  word-break: break-all;
  margin: 0;
  padding: 2px;
  overflow: auto;
}

div.jsoneditor-default {
  color: gray;
  padding-left: 10px;
}

div.jsoneditor-tree {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  overflow: auto;
}

div.jsoneditor-tree button.jsoneditor-button {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("jsoneditor-icons.0b983fb8.svg");
  border: none;
  margin: 0;
  padding: 0;
}

div.jsoneditor-tree button.jsoneditor-button:focus {
  background-color: #f5f5f5;
  outline: 1px solid #e5e5e5;
}

div.jsoneditor-tree button.jsoneditor-collapsed {
  background-position: 0 -48px;
}

div.jsoneditor-tree button.jsoneditor-expanded {
  background-position: 0 -72px;
}

div.jsoneditor-tree button.jsoneditor-contextmenu-button {
  background-position: -48px -72px;
}

div.jsoneditor-tree button.jsoneditor-invisible {
  visibility: hidden;
  background: none;
}

div.jsoneditor-tree button.jsoneditor-dragarea {
  cursor: move;
  background-image: url("jsoneditor-icons.0b983fb8.svg");
  background-position: -72px -72px;
}

div.jsoneditor-tree :focus {
  outline: none;
}

div.jsoneditor-tree div.jsoneditor-show-more {
  color: gray;
  background-color: #e5e5e5;
  border-radius: 3px;
  margin: 2px 0;
  padding: 3px 4px;
  font-family: arial, sans-serif;
  font-size: 14px;
  display: inline-block;
}

div.jsoneditor-tree div.jsoneditor-show-more a {
  color: gray;
  display: inline-block;
}

div.jsoneditor-tree div.jsoneditor-color {
  width: 12px;
  height: 12px;
  cursor: pointer;
  border: 1px solid gray;
  margin: 4px;
  display: inline-block;
}

div.jsoneditor-tree div.jsoneditor-color.jsoneditor-color-readonly {
  cursor: inherit;
}

div.jsoneditor-tree div.jsoneditor-date {
  color: #fff;
  background: #a1a1a1;
  border-radius: 3px;
  margin: 0 3px;
  padding: 3px;
  font-family: arial, sans-serif;
  display: inline-block;
}

div.jsoneditor-tree table.jsoneditor-tree {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

div.jsoneditor-tree .jsoneditor-button {
  display: block;
}

div.jsoneditor-tree .jsoneditor-button.jsoneditor-schema-error {
  width: 24px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("jsoneditor-icons.0b983fb8.svg");
  background-position: -168px -48px;
  margin: 0 4px 0 0;
  padding: 0;
}

div.jsoneditor-outer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: static;
}

div.jsoneditor-outer.has-nav-bar {
  margin-top: -26px;
  padding-top: 26px;
}

div.jsoneditor-outer.has-nav-bar.has-main-menu-bar {
  margin-top: -61px;
  padding-top: 61px;
}

div.jsoneditor-outer.has-status-bar {
  margin-bottom: -26px;
  padding-bottom: 26px;
}

div.jsoneditor-outer.has-main-menu-bar {
  margin-top: -35px;
  padding-top: 35px;
}

div.jsoneditor-busy {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  display: none;
  position: absolute;
  top: 15%;
  left: 0;
}

div.jsoneditor-busy span {
  background-color: #ffffab;
  border: 1px solid #fe0;
  border-radius: 3px;
  padding: 5px 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, .4);
}

div.jsoneditor-field.jsoneditor-empty:after, div.jsoneditor-value.jsoneditor-empty:after {
  pointer-events: none;
  color: #d3d3d3;
  font-size: 8pt;
}

div.jsoneditor-value.jsoneditor-url, a.jsoneditor-value.jsoneditor-url {
  color: #006000;
  text-decoration: underline;
}

a.jsoneditor-value.jsoneditor-url {
  margin: 2px;
  padding: 2px;
  display: inline-block;
}

a.jsoneditor-value.jsoneditor-url:hover, a.jsoneditor-value.jsoneditor-url:focus {
  color: #ee422e;
}

div.jsoneditor-field[contenteditable="true"]:focus, div.jsoneditor-field[contenteditable="true"]:hover, div.jsoneditor-value[contenteditable="true"]:focus, div.jsoneditor-value[contenteditable="true"]:hover, div.jsoneditor-field.jsoneditor-highlight, div.jsoneditor-value.jsoneditor-highlight {
  background-color: #ffffab;
  border: 1px solid #fe0;
  border-radius: 2px;
}

div.jsoneditor-field.jsoneditor-highlight-active, div.jsoneditor-field.jsoneditor-highlight-active:focus, div.jsoneditor-field.jsoneditor-highlight-active:hover, div.jsoneditor-value.jsoneditor-highlight-active, div.jsoneditor-value.jsoneditor-highlight-active:focus, div.jsoneditor-value.jsoneditor-highlight-active:hover {
  background-color: #fe0;
  border: 1px solid #ffc700;
  border-radius: 2px;
}

div.jsoneditor-value.jsoneditor-object, div.jsoneditor-value.jsoneditor-array {
  min-width: 16px;
}

div.jsoneditor-tree button.jsoneditor-contextmenu-button:hover, div.jsoneditor-tree button.jsoneditor-contextmenu-button:focus, div.jsoneditor-tree button.jsoneditor-contextmenu-button.jsoneditor-selected, tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-contextmenu-button {
  background-position: -48px -48px;
}

div.jsoneditor-tree div.jsoneditor-show-more a:hover, div.jsoneditor-tree div.jsoneditor-show-more a:focus {
  color: #ee422e;
}

textarea.jsoneditor-text, .ace-jsoneditor {
  min-height: 150px;
}

textarea.jsoneditor-text.ace_editor, .ace-jsoneditor.ace_editor {
  font-family: consolas, menlo, monaco, Ubuntu Mono, source-code-pro, monospace;
}

textarea.jsoneditor-text {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  resize: none;
  background-color: #fff;
  border: none;
  outline-width: 0;
  margin: 0;
}

tr.jsoneditor-highlight, tr.jsoneditor-selected {
  background-color: #d3d3d3;
}

tr.jsoneditor-selected button.jsoneditor-dragarea, tr.jsoneditor-selected button.jsoneditor-contextmenu-button {
  visibility: hidden;
}

tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-dragarea, tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-contextmenu-button {
  visibility: visible;
}

div.jsoneditor-tree button.jsoneditor-dragarea:hover, div.jsoneditor-tree button.jsoneditor-dragarea:focus, tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-dragarea {
  background-position: -72px -48px;
}

div.jsoneditor tr, div.jsoneditor th, div.jsoneditor td {
  margin: 0;
  padding: 0;
}

div.jsoneditor-field, div.jsoneditor-value, div.jsoneditor td, div.jsoneditor th, div.jsoneditor textarea, pre.jsoneditor-preview, .jsoneditor-schema-error, .jsoneditor-popover {
  color: #1a1a1a;
  font-family: consolas, menlo, monaco, Ubuntu Mono, source-code-pro, monospace;
  font-size: 14px;
}

.jsoneditor-schema-error {
  cursor: default;
  height: 24px;
  text-align: center;
  width: 24px;
  line-height: 24px;
  display: inline-block;
  position: relative;
}

.jsoneditor-popover {
  color: #fff;
  cursor: auto;
  width: 200px;
  background-color: #4c4c4c;
  border-radius: 3px;
  padding: 7px 10px;
  position: absolute;
  box-shadow: 0 0 5px rgba(0, 0, 0, .4);
}

.jsoneditor-popover.jsoneditor-above {
  bottom: 32px;
  left: -98px;
}

.jsoneditor-popover.jsoneditor-above:before {
  border-top: 7px solid #4c4c4c;
  bottom: -7px;
}

.jsoneditor-popover.jsoneditor-below {
  top: 32px;
  left: -98px;
}

.jsoneditor-popover.jsoneditor-below:before {
  border-bottom: 7px solid #4c4c4c;
  top: -7px;
}

.jsoneditor-popover.jsoneditor-left {
  top: -7px;
  right: 32px;
}

.jsoneditor-popover.jsoneditor-left:before {
  content: "";
  top: 19px;
  right: -14px;
  left: inherit;
  margin-left: inherit;
  border-top: 7px solid rgba(0, 0, 0, 0);
  border-bottom: 7px solid rgba(0, 0, 0, 0);
  border-left: 7px solid #4c4c4c;
  margin-top: -7px;
  position: absolute;
}

.jsoneditor-popover.jsoneditor-right {
  top: -7px;
  left: 32px;
}

.jsoneditor-popover.jsoneditor-right:before {
  content: "";
  margin-left: inherit;
  border-top: 7px solid rgba(0, 0, 0, 0);
  border-bottom: 7px solid rgba(0, 0, 0, 0);
  border-right: 7px solid #4c4c4c;
  margin-top: -7px;
  position: absolute;
  top: 19px;
  left: -14px;
}

.jsoneditor-popover:before {
  content: "";
  border-left: 7px solid rgba(0, 0, 0, 0);
  border-right: 7px solid rgba(0, 0, 0, 0);
  margin-left: -7px;
  display: block;
  position: absolute;
  left: 50%;
}

.jsoneditor-text-errors tr.jump-to-line:hover {
  cursor: pointer;
  text-decoration: underline;
}

.jsoneditor-schema-error:hover .jsoneditor-popover, .jsoneditor-schema-error:focus .jsoneditor-popover {
  -webkit-animation: .3s linear fade-in, .3s linear move-up;
  animation: .3s linear fade-in, .3s linear move-up;
  display: block;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.jsoneditor .jsoneditor-validation-errors-container {
  max-height: 130px;
  overflow-y: auto;
}

.jsoneditor .jsoneditor-validation-errors {
  width: 100%;
  overflow: hidden;
}

.jsoneditor .jsoneditor-additional-errors {
  color: gray;
  background-color: #ebebeb;
  border-radius: 8px;
  margin: auto;
  padding: 7px 15px;
  position: absolute;
  bottom: 31px;
  left: calc(50% - 92px);
}

.jsoneditor .jsoneditor-additional-errors.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.jsoneditor .jsoneditor-additional-errors.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.jsoneditor .jsoneditor-text-errors {
  width: 100%;
  border-collapse: collapse;
  border-top: 1px solid #ffc700;
}

.jsoneditor .jsoneditor-text-errors td {
  vertical-align: middle;
  padding: 3px 6px;
}

.jsoneditor .jsoneditor-text-errors td pre {
  white-space: pre-wrap;
  margin: 0;
}

.jsoneditor .jsoneditor-text-errors tr {
  background-color: #ffffab;
}

.jsoneditor .jsoneditor-text-errors tr.parse-error {
  background-color: rgba(238, 46, 46, .44);
}

.jsoneditor-text-errors .jsoneditor-schema-error {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
  margin: 0 4px 0 0;
  padding: 0;
}

.jsoneditor-text-errors tr .jsoneditor-schema-error {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("jsoneditor-icons.0b983fb8.svg");
  background-position: -168px -48px;
}

.jsoneditor-text-errors tr.parse-error .jsoneditor-schema-error {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("jsoneditor-icons.0b983fb8.svg");
  background-position: -25px 0;
}

.jsoneditor-anchor {
  cursor: pointer;
}

.jsoneditor-anchor .picker_wrapper.popup.popup_bottom {
  top: 28px;
  left: -10px;
}

.fadein {
  -webkit-animation: .3s fadein;
  animation: .3s fadein;
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.jsoneditor-modal input[type="search"].selectr-input {
  width: calc(100% - 4px);
  box-sizing: border-box;
  border: 1px solid #d3d3d3;
  margin: 2px;
  padding: 4px;
}

.jsoneditor-modal button.selectr-input-clear {
  right: 8px;
}

.jsoneditor-menu {
  width: 100%;
  height: 35px;
  box-sizing: border-box;
  color: #fff;
  background-color: #3883fa;
  border-bottom: 1px solid #3883fa;
  margin: 0;
  padding: 2px;
}

.jsoneditor-menu > button, .jsoneditor-menu > .jsoneditor-modes > button {
  width: 26px;
  height: 26px;
  color: #fff;
  opacity: .8;
  float: left;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("jsoneditor-icons.0b983fb8.svg");
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 2px;
  margin: 2px;
  padding: 0;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.jsoneditor-menu > button:hover, .jsoneditor-menu > .jsoneditor-modes > button:hover {
  background-color: rgba(255, 255, 255, .2);
  border: 1px solid rgba(255, 255, 255, .4);
}

.jsoneditor-menu > button:focus, .jsoneditor-menu > button:active, .jsoneditor-menu > .jsoneditor-modes > button:focus, .jsoneditor-menu > .jsoneditor-modes > button:active {
  background-color: rgba(255, 255, 255, .3);
}

.jsoneditor-menu > button:disabled, .jsoneditor-menu > .jsoneditor-modes > button:disabled {
  opacity: .5;
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.jsoneditor-menu > button.jsoneditor-collapse-all {
  background-position: 0 -96px;
}

.jsoneditor-menu > button.jsoneditor-expand-all {
  background-position: 0 -120px;
}

.jsoneditor-menu > button.jsoneditor-sort {
  background-position: -120px -96px;
}

.jsoneditor-menu > button.jsoneditor-transform {
  background-position: -144px -96px;
}

.jsoneditor.jsoneditor-mode-view > .jsoneditor-menu > button.jsoneditor-sort, .jsoneditor.jsoneditor-mode-form > .jsoneditor-menu > button.jsoneditor-sort, .jsoneditor.jsoneditor-mode-view > .jsoneditor-menu > button.jsoneditor-transform, .jsoneditor.jsoneditor-mode-form > .jsoneditor-menu > button.jsoneditor-transform {
  display: none;
}

.jsoneditor-menu > button.jsoneditor-undo {
  background-position: -24px -96px;
}

.jsoneditor-menu > button.jsoneditor-undo:disabled {
  background-position: -24px -120px;
}

.jsoneditor-menu > button.jsoneditor-redo {
  background-position: -48px -96px;
}

.jsoneditor-menu > button.jsoneditor-redo:disabled {
  background-position: -48px -120px;
}

.jsoneditor-menu > button.jsoneditor-compact {
  background-position: -72px -96px;
}

.jsoneditor-menu > button.jsoneditor-format {
  background-position: -72px -120px;
}

.jsoneditor-menu > button.jsoneditor-repair {
  background-position: -96px -96px;
}

.jsoneditor-menu > .jsoneditor-modes {
  float: left;
  display: inline-block;
}

.jsoneditor-menu > .jsoneditor-modes > button {
  width: auto;
  background-image: none;
  padding-left: 6px;
  padding-right: 6px;
}

.jsoneditor-menu > button.jsoneditor-separator, .jsoneditor-menu > .jsoneditor-modes > button.jsoneditor-separator {
  margin-left: 10px;
}

.jsoneditor-menu a {
  color: #fff;
  opacity: .8;
  vertical-align: middle;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.jsoneditor-menu a:hover {
  opacity: 1;
}

.jsoneditor-menu a.jsoneditor-poweredBy {
  padding: 10px;
  font-size: 8pt;
  position: absolute;
  top: 0;
  right: 0;
}

.jsoneditor-navigation-bar {
  width: 100%;
  height: 26px;
  box-sizing: border-box;
  color: gray;
  background-color: #ebebeb;
  border-bottom: 1px solid #d3d3d3;
  margin: 0;
  padding: 0;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 26px;
  overflow: hidden;
}

.jsoneditor-search {
  border-collapse: collapse;
  border-spacing: 0;
  font-family: arial, sans-serif;
  display: flex;
  position: absolute;
  top: 4px;
  right: 4px;
}

.jsoneditor-search input {
  color: #1a1a1a;
  width: 120px;
  border: none;
  outline: none;
  margin: 1px;
  font-family: arial, sans-serif;
  line-height: 20px;
}

.jsoneditor-search button {
  width: 16px;
  height: 24px;
  vertical-align: top;
  background: url("jsoneditor-icons.0b983fb8.svg");
  border: none;
  margin: 0;
  padding: 0;
}

.jsoneditor-search button:hover {
  background-color: rgba(0, 0, 0, 0);
}

.jsoneditor-search button.jsoneditor-refresh {
  width: 18px;
  background-position: -99px -73px;
}

.jsoneditor-search button.jsoneditor-next {
  cursor: pointer;
  background-position: -124px -73px;
}

.jsoneditor-search button.jsoneditor-next:hover {
  background-position: -124px -49px;
}

.jsoneditor-search button.jsoneditor-previous {
  cursor: pointer;
  background-position: -148px -73px;
  margin-right: 2px;
}

.jsoneditor-search button.jsoneditor-previous:hover {
  background-position: -148px -49px;
}

.jsoneditor-results {
  color: #fff;
  padding-right: 5px;
  font-family: arial, sans-serif;
  line-height: 26px;
}

.jsoneditor-frame {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0 2px;
}

.jsoneditor-statusbar {
  height: 26px;
  color: gray;
  box-sizing: border-box;
  background-color: #ebebeb;
  border-top: 1px solid #d3d3d3;
  font-size: 14px;
  line-height: 26px;
}

.jsoneditor-statusbar > .jsoneditor-curserinfo-val {
  margin-right: 12px;
}

.jsoneditor-statusbar > .jsoneditor-curserinfo-count {
  margin-left: 4px;
}

.jsoneditor-statusbar > .jsoneditor-validation-error-icon {
  float: right;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: url("jsoneditor-icons.0b983fb8.svg");
  background-position: -168px -48px;
  margin-top: 1px;
  padding: 0;
}

.jsoneditor-statusbar > .jsoneditor-validation-error-count {
  float: right;
  cursor: pointer;
  margin: 0 4px 0 0;
}

.jsoneditor-statusbar > .jsoneditor-parse-error-icon {
  float: right;
  width: 24px;
  height: 24px;
  background-image: url("jsoneditor-icons.0b983fb8.svg");
  background-position: -25px 0;
  margin: 1px;
  padding: 0;
}

.jsoneditor-statusbar .jsoneditor-array-info a {
  color: inherit;
}

div.jsoneditor-statusbar > .jsoneditor-curserinfo-label, div.jsoneditor-statusbar > .jsoneditor-size-info {
  margin: 0 4px;
}

.jsoneditor-treepath {
  white-space: nowrap;
  outline: none;
  padding: 0 5px;
  overflow: hidden;
}

.jsoneditor-treepath.show-all {
  word-wrap: break-word;
  white-space: normal;
  z-index: 1;
  background-color: #ebebeb;
  position: absolute;
  box-shadow: 2px 2px 12px rgba(128, 128, 128, .3);
}

.jsoneditor-treepath.show-all span.jsoneditor-treepath-show-all-btn {
  display: none;
}

.jsoneditor-treepath div.jsoneditor-contextmenu-root {
  position: absolute;
  left: 0;
}

.jsoneditor-treepath .jsoneditor-treepath-show-all-btn {
  height: 20px;
  cursor: pointer;
  background-color: #ebebeb;
  padding: 0 3px;
  position: absolute;
  left: 0;
}

.jsoneditor-treepath .jsoneditor-treepath-element {
  margin: 1px;
  font-family: arial, sans-serif;
  font-size: 14px;
}

.jsoneditor-treepath .jsoneditor-treepath-seperator {
  margin: 2px;
  font-family: arial, sans-serif;
  font-size: 9pt;
}

.jsoneditor-treepath span.jsoneditor-treepath-element:hover, .jsoneditor-treepath span.jsoneditor-treepath-seperator:hover {
  cursor: pointer;
  text-decoration: underline;
}

.selectr-container {
  position: relative;
}

.selectr-container li {
  list-style: none;
}

.selectr-hidden {
  clip: rect(0px, 0px, 0px, 0px);
  width: 1px;
  height: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.selectr-visible {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 11;
  position: absolute;
  top: 0;
  left: 0;
}

.selectr-desktop.multiple .selectr-visible {
  display: none;
}

.selectr-desktop.multiple.native-open .selectr-visible {
  height: auto;
  opacity: 1;
  display: block;
  top: 100%;
  min-height: 200px !important;
}

.selectr-container.multiple.selectr-mobile .selectr-selected {
  z-index: 0;
}

.selectr-selected {
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 7px 28px 7px 14px;
  position: relative;
}

.selectr-selected:before {
  width: 0;
  height: 0;
  content: "";
  border: 4px solid rgba(0, 0, 0, 0);
  border-top-color: #6c7a86;
  border-bottom-width: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: rotate(0)translate3d(0, -50%, 0);
  transform: rotate(0)translate3d(0, -50%, 0);
}

.selectr-container.open .selectr-selected:before, .selectr-container.native-open .selectr-selected:before {
  border: 4px solid rgba(0, 0, 0, 0);
  border-top-width: 0;
  border-bottom-color: #6c7a86;
}

.selectr-label {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: none;
  overflow: hidden;
}

.selectr-placeholder {
  color: #6c7a86;
}

.selectr-tags {
  white-space: normal;
  margin: 0;
  padding: 0;
}

.has-selected .selectr-tags {
  margin: 0 0 -2px;
}

.selectr-tag {
  float: left;
  cursor: default;
  color: #fff;
  background: #acb7bf;
  border: none;
  border-radius: 10px;
  margin: 0 2px 2px 0;
  padding: 2px 25px 2px 8px;
  list-style: none;
  position: relative;
}

.selectr-container.multiple.has-selected .selectr-selected {
  padding: 5px 28px 5px 5px;
}

.selectr-options-container {
  z-index: 10000;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border: 1px solid #999;
  border-top: 0 solid rgba(0, 0, 0, 0);
  border-radius: 0 0 3px 3px;
  display: none;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
}

.selectr-container.open .selectr-options-container {
  display: block;
}

.selectr-input-container {
  display: none;
  position: relative;
}

.selectr-clear, .selectr-input-clear, .selectr-tag-remove {
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 22px;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.selectr-clear, .selectr-input-clear {
  display: none;
}

.selectr-container.has-selected .selectr-clear, .selectr-input-container.active .selectr-input-clear {
  display: block;
}

.selectr-selected .selectr-tag-remove {
  right: 2px;
}

.selectr-clear:before, .selectr-clear:after, .selectr-input-clear:before, .selectr-input-clear:after, .selectr-tag-remove:before, .selectr-tag-remove:after {
  width: 2px;
  height: 10px;
  content: " ";
  background-color: #6c7a86;
  position: absolute;
  top: 5px;
  left: 9px;
}

.selectr-tag-remove:before, .selectr-tag-remove:after {
  width: 3px;
  height: 12px;
  background-color: #fff;
  top: 4px;
}

.selectr-clear:before, .selectr-input-clear:before, .selectr-tag-remove:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.selectr-clear:after, .selectr-input-clear:after, .selectr-tag-remove:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.selectr-input-container.active, .selectr-input-container.active .selectr-clear {
  display: block;
}

.selectr-input {
  box-sizing: border-box;
  width: calc(100% - 30px);
  border: 1px solid #999;
  border-radius: 3px;
  margin: 10px 15px;
  padding: 7px 30px 7px 9px;
  top: 5px;
  left: 5px;
}

.selectr-notice {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #999;
  border-radius: 0 0 3px 3px;
  padding: 8px 16px;
  display: none;
}

.selectr-container.notice .selectr-notice {
  display: block;
}

.selectr-container.notice .selectr-selected {
  border-radius: 3px 3px 0 0;
}

.selectr-options {
  max-height: 200px;
  margin: 0;
  padding: 0;
  display: none;
  position: relative;
  top: calc(100% + 2px);
  overflow-x: auto;
  overflow-y: scroll;
}

.selectr-container.open .selectr-options, .selectr-container.open .selectr-input-container, .selectr-container.notice .selectr-options-container {
  display: block;
}

.selectr-option {
  list-style: outside none none;
  cursor: pointer;
  padding: 5px 20px;
  font-weight: normal;
  display: block;
  position: relative;
}

.selectr-options.optgroups > .selectr-option {
  padding-left: 25px;
}

.selectr-optgroup {
  padding: 0;
  font-weight: bold;
}

.selectr-optgroup--label {
  margin-top: 10px;
  padding: 5px 15px;
  font-weight: bold;
}

.selectr-match {
  text-decoration: underline;
}

.selectr-option.selected {
  background-color: #ddd;
}

.selectr-option.active {
  color: #fff;
  background-color: #5897fb;
}

.selectr-option.disabled {
  opacity: .4;
}

.selectr-option.excluded {
  display: none;
}

.selectr-container.open .selectr-selected {
  border-color: #999 #999 rgba(0, 0, 0, 0);
  border-radius: 3px 3px 0 0;
}

.selectr-container.open .selectr-selected:after {
  -webkit-transform: rotate(180deg)translate3d(0, 50%, 0);
  transform: rotate(180deg)translate3d(0, 50%, 0);
}

.selectr-disabled {
  opacity: .6;
}

.selectr-empty, .has-selected .selectr-placeholder {
  display: none;
}

.has-selected .selectr-label {
  display: block;
}

.taggable .selectr-selected {
  padding: 4px 28px 4px 4px;
}

.taggable .selectr-selected:after {
  content: " ";
  clear: both;
  display: table;
}

.taggable .selectr-label {
  width: auto;
}

.taggable .selectr-tags {
  float: left;
  display: block;
}

.taggable .selectr-placeholder {
  display: none;
}

.input-tag {
  float: left;
  min-width: 90px;
  width: auto;
}

.selectr-tag-input {
  width: 100%;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  border: none;
  padding: 3px 10px;
}

.selectr-input-container.loading:after {
  width: 20px;
  height: 20px;
  content: "";
  -o-transform-origin: 50% 0 0;
  -ms-transform-origin: 50% 0 0;
  -moz-transform-origin: 50% 0 0;
  -webkit-transform-origin: 50% 0 0;
  transform-origin: 50% 0 0;
  border: 3px solid #ddd;
  border-top-color: #aaa;
  border-radius: 50%;
  -webkit-animation: .5s linear infinite forwards selectr-spin;
  animation: .5s linear infinite forwards selectr-spin;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

@-webkit-keyframes selectr-spin {
  0% {
    -webkit-transform: rotate(0)translate3d(0, -50%, 0);
    transform: rotate(0)translate3d(0, -50%, 0);
  }

  100% {
    -webkit-transform: rotate(360deg)translate3d(0, -50%, 0);
    transform: rotate(360deg)translate3d(0, -50%, 0);
  }
}

@keyframes selectr-spin {
  0% {
    -webkit-transform: rotate(0)translate3d(0, -50%, 0);
    transform: rotate(0)translate3d(0, -50%, 0);
  }

  100% {
    -webkit-transform: rotate(360deg)translate3d(0, -50%, 0);
    transform: rotate(360deg)translate3d(0, -50%, 0);
  }
}

.selectr-container.open.inverted .selectr-selected {
  border-color: rgba(0, 0, 0, 0) #999 #999;
  border-radius: 0 0 3px 3px;
}

.selectr-container.inverted .selectr-options-container {
  background-color: #fff;
  border-width: 1px 1px 0;
  border-color: #999 #999 rgba(0, 0, 0, 0);
  border-radius: 3px 3px 0 0;
  top: auto;
  bottom: calc(100% - 1px);
}

.selectr-container ::-webkit-input-placeholder {
  color: #6c7a86;
  opacity: 1;
}

.selectr-container ::-moz-placeholder {
  color: #6c7a86;
  opacity: 1;
}

.selectr-container :-ms-input-placeholder {
  color: #6c7a86;
  opacity: 1;
}

.selectr-container ::-webkit-input-placeholder {
  color: #6c7a86;
  opacity: 1;
}

.selectr-container ::placeholder {
  color: #6c7a86;
  opacity: 1;
}

/*# sourceMappingURL=index.3a6862b0.css.map */
